<template>
    <v-list-item
        id="mediaTableListItem"
        v-bind="itemBindings"
        @click="onClick"
    >
        <v-list-item-action v-if="selectable">
            <v-checkbox
                v-show="isSelectable"
                :disabled="!isSelectable"
                id="mediaTableListItemCheckbox"
                color="primary"
                v-model="isSelected"
                hide-details
                @click="onSelect"
            />
        </v-list-item-action>
        <v-list-item-icon class="d-flex justify-center" style="width: 42px">
            <media-icon
                :item="item"
                :size="sizeIcon"
                :disabled="isDisabledItem"
            />
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title
                class="font-weight-bold"
            >
                {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
                <span v-if="!isMobile">{{ item | mediaTypeLabel }}</span>
                <span v-else>
                    {{ item.updated_at | dateFormat('DD.MM.YYYY') }}
                </span>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="ma-0">
            <div class="d-flex align-center caption">
                <v-sheet
                    v-if="!isMobile && !hideAuthor"
                    :width="labelSize * 4"
                    class="text--secondary text-truncate mx-3"
                >
                    {{ item.author | memberFullName('short') }}
                </v-sheet>
                <v-sheet
                    v-if="!isMobile && !hideSize"
                    class="text--secondary mr-3"
                    :width="labelSize * 3"
                >
                    {{ item | mediaSize }}
                </v-sheet>
                <v-sheet
                    v-if="!isMobile && !hideDate"
                    class="text--secondary text-truncate"
                    :width="labelSize * 3"
                >
                    {{ item.updated_at | dateFormat('DD.MM.YYYY') }}
                </v-sheet>
                <v-sheet
                    v-if="editable"
                    :width="labelSize"
                    class="d-flex justify-end"
                >
                    <w-actions-menu
                        v-if="showActionsMenu"
                        :items="actions"
                    />
                    <v-tooltip bottom>
                        <template #activator="{ on, attr }">
                            <v-btn
                                v-if="showDetachSharedAction"
                                icon
                                v-on="on"
                                v-bind="attr"
                                @click="onDetachShared"
                            >
                                <w-icon
                                    color="error"
                                    value="CLOSE"
                                />
                            </v-btn>
                        </template>
                        <span>{{ $trans('Exclude') }}</span>
                    </v-tooltip>
                </v-sheet>
            </div>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import mediaTypeLabelFilterMixin from '@apps/media/mixins/mediaTypeLabelFilterMixin'
import MediaIcon from '@apps/media/components/MediaIcon'
import { mediaTypes } from '@apps/media/tools/config'
import { extension, isImage } from '@apps/media/tools'
import mediaSizeFilterMixin from '@apps/media/mixins/mediaSizeFilterMixin'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
import isMediaViewSharedFlagMixin from '@apps/media/mixins/isMediaViewSharedFlagMixin'

export default {
    name: 'MediaTableListItem',
    components: { MediaIcon },
    mixins: [
        mediaTypeLabelFilterMixin,
        mediaSizeFilterMixin,
        mobileBreakpointChecker,
        hasMemberFullNameFilterMixin,
        isMediaViewSharedFlagMixin
    ],
    props: {
        item: {
            type: Object,
            default: null
        },
        labelSize: {
            type: [String, Number],
            default: 56
        },
        actions: {
            type: Array,
            default: null
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selectableTypes: {
            type: Array,
            default: null
        },
        editable: {
            type: Boolean,
            default: false
        },
        viewable: {
            type: Boolean,
            default: false
        },
        disabledItem: {
            type: Boolean,
            default: false
        },
        hideAuthor: {
            type: Boolean,
            default: false
        },
        hideSize: {
            type: Boolean,
            default: false
        },
        hideDate: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isSelectable() {
            const accept = this.selectableTypes

            if(!accept) {
                return true
            }

            const ext = extension(this.item.src)

            return accept.includes(ext) || accept.includes(this.item.type)
        },
        isDisabledItem() {
            return this.item.type !== mediaTypes.FOLDER &&
                (this.disabledItem || !this.isSelectable)
        },
        sizeIcon() {
            if(this.item.type === mediaTypes.FOLDER) {
                return 26
            }

            if(isImage(this.item.src)) {
                return 30
            }

            return 20
        },
        itemBindings() {
            const bindings = {
                class: ['media-table-list-item'],
                disabled: this.isDisabledItem
            }

            if(this.isSelected) {
                bindings.class.push('media-table-list-item--selected')
            }

            return bindings
        },
        showActionsMenu() {
            return this.editable &&
                !this.isMediaViewShared
        },
        showDetachSharedAction() {
            return this.editable &&
                this.isMediaViewShared &&
                this.item.shared &&
                !!this.item.shared.find(o => {
                    return o.shared_by === this.$auth.user.uuid &&
                        o.media_uuid === this.item.uuid
                })
        }
    },
    data() {
        return {
            isSelected: false
        }
    },
    methods: {
        onClick(e) {
            if(this.viewable || this.item.type === mediaTypes.FOLDER) {
                this.$emit('click', this.item)
            } else if(this.selectable) {
                this.isSelected = !this.isSelected
                this.onSelect(e)
            }
        },
        onSelect(e) {
            e.stopPropagation()

            const event = this.isSelected
                ? 'checked'
                : 'unchecked'

            this.$emit(event, this.item)
        },
        onDetachShared(e) {
            e.stopPropagation()

            this.$emit('detach:shared', this.item)
        }
    }
}
</script>

<style lang=scss>
.media-table-list-item {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        transition: all .3ms;
    }

    &:hover {
        &:before {
            opacity: 0.04 !important;
            background-color: var(--v-primary-base);
        }
    }
}
.media-table-list-item {
    &--selected:before {
        opacity: 0.12;
        background-color: var(--v-primary-base);
    }
}
</style>
